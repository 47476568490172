import React from "react"
import styled from "styled-components"

import ImageSwiper from "../ImageSwiper"
import Section from "../Section"
import Slice from "../Slice"


const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.mineShaft};
  width: 100%;
  position: relative;

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 80px 0 40px 0;
    gap: 50px;

    .title-container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      h1 {
        color: ${({ theme }) => theme.palette.white};
        font-size: 52px;
        line-height: 45px;
        margin: 0;

        span {
          color: ${({ theme }) => theme.palette.supernova};
        }
      }

      p {
        color: ${({ theme }) => theme.palette.white};
        margin: 0;
      }
    }

    .image-container {
      width: 100%;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    .content {
      padding: 150px 0;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .title-container {
        h1 {
          font-size: 82px;
          line-height: 75px;
          margin: 0;
        }
      }

      .image-container {
        width: 20%;
      }

      .title-container,
      .image-container {
        flex: 1 1 0px;
      }
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    .content {
      gap: 150px;
    }
  }
`

export interface HeaderType {
  images: any[]
}

const Header: React.FC<HeaderType> = ({images}) => {
  return (
    <Container>
      <Section>
        <div className="content">
          <div className="title-container">
            <h1>
              Our <span>services</span>.
            </h1>
            <p>
              Players offers a wide variety of services that cater for every
              level of swimming. From learning how to float to the professional
              standard that aims for international success.
              <br />
              <br />
              Our services are divided into groups based on the swimmers&apos;
              skill levels and future goals to ensure that everyone has a place
              to fit in.
            </p>
          </div>
          <div className="image-container">
            <ImageSwiper images={images} />
          </div>
        </div>
      </Section>
      <Slice color="#ffffff" style={{ position: "absolute", bottom: "0" }} />
    </Container>
  )
}

export default Header
