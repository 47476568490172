import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import ImageSwiper from "../ImageSwiper"
import Section from "../Section"
import SectionHeading from "../SectionHeading"

import Icon from "../Icon"
import iconTime from "../../images/services/icon-time.svg"
import iconBill from "../../images/services/icon-bill.svg"

const Container = styled.div<{ textPlacement: string }>`
  margin: 50px 0;

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .service-container {
      .classes {
        h3 {
          font-size: 16px;
          margin-bottom: 10px;
          color: ${({ theme }) => theme.palette.scienceBlue};
        }

        div {
          display: flex;
          gap: 10px;
          align-items: center;

          p {
            margin: 0;
          }
        }
      }

      .enquire-link {
        background-color: ${({ theme }) => `${theme.palette.scienceBlue}15`};
        padding: 10px 25px;
        border-radius: 10px;
        transition: background-color 0.2s;

        :hover {
          background-color: ${({ theme }) => `${theme.palette.scienceBlue}25`};
        }
      }
      a {
        color: ${({ theme }) => theme.palette.mineShaft};
        text-decoration: none;
        font-size: 12px;
        display: flex;
        gap: 0px;
        align-items: center;
        cursor: pointer;
        margin-top: 20px;
        transition: gap 0.2s;

        :hover {
          gap: 5px;
        }
      }
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin: 70px 0;
    .content {
      flex-direction: ${({ textPlacement }) =>
        textPlacement == "left" ? "row" : "row-reverse"};
      gap: 50px;
      align-items: center;
      justify-content: space-between;

      .image-container {
        width: 20%;
      }

      .image-container,
      .service-container {
        flex: 1 1 0px; //flex-grow flex-shrink flex-basis
      }
    }
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 100px 0;
    .content {
      gap: 150px;
    }
  }
`

export interface ServiceType {
  id?: string
  title: string
  description: string
  minClasses: number
  maxClasses: number | null
  enquireLink: string
  billTerm: "monthly" | "termly"
  images: any[]
  textPlacement: "left" | "right"
}

const ServiceSection: React.FC<ServiceType> = ({
  id = "",
  title,
  description,
  minClasses,
  maxClasses,
  billTerm,
  enquireLink,
  images,
  textPlacement,
}) => {
  return (
    <Container textPlacement={textPlacement} id={id}>
      <Section>
        <div className="content">
          <div className="service-container">
            <SectionHeading heading={title} />
            <p>{description}</p>
            <div className="classes">
              <h3>Classes available</h3>
              <div>
                <Icon src={iconTime} size={24} />{" "}
                {maxClasses ? (
                  <p>
                    {minClasses} - {maxClasses} per week
                  </p>
                ) : (
                  <p>{minClasses} per week</p>
                )}
              </div>
              <div>
                <Icon src={iconBill} size={24} />
                <p>Billed {billTerm}</p>
              </div>
            </div>
            {/* <a href={enquireLink}>
              <div className="enquire-link">Enquire now</div>
            </a> */}
          </div>
          <div className="image-container">
            <ImageSwiper images={images} />
          </div>
        </div>
      </Section>
    </Container>
  )
}

export default ServiceSection
