import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import Header from "../components/services/Header"
import ServiceSection, {
  ServiceType,
} from "../components/services/ServiceSection"
import Slice from "../components/Slice"

import { graphql, useStaticQuery } from "gatsby"
import { getImage, IGatsbyImageData } from "gatsby-plugin-image"

const ServicesPage = (props) => {
  const ImageDataQuery = graphql`
    query {
      allImageSharp(
        filter: { fixed: { originalName: { regex: "/service/" } } }
        sort: { fields: fixed___originalName }
      ) {
        edges {
          node {
            gatsbyImageData(
              layout: CONSTRAINED
              quality: 100
              placeholder: TRACED_SVG
            )
          }
        }
      }
    }
  `

  const imageQuery = useStaticQuery(ImageDataQuery)

  const getImageArray = (substring: string) => {
    const images = imageQuery.allImageSharp.edges
    const imgArray: (IGatsbyImageData | undefined)[] = []
    images.forEach((image) => {
      const source = image.node.gatsbyImageData.images.sources[0].srcSet
      if (source.includes(substring)) {
        imgArray.push(getImage(image.node))
      }
    })

    return imgArray
  }

  const services: ServiceType[] = [
    {
      id: "learn-to-swim",
      title: "Learn to swim.",
      description:
        "Enrol your little ones in a water safety course where they will learn the necessary skills to keep themselves safe in the water. Keep your nerves at ease, knowing that your kids can float safely until help arrives.",
      minClasses: 1,
      maxClasses: 4,
      billTerm: "termly",
      enquireLink: "",
      images: getImageArray("lts"),
      textPlacement: "right",
    },
    {
      id: "group-1",
      title: "Group one.",
      description:
        "A technique-driven group, aimed to help younger school swimmers with the basics of competitive swimming. The swimmers will focus on improving their success at school level and will also be introduced to regional level competitions.",
      minClasses: 2,
      maxClasses: 4,
      billTerm: "monthly",
      enquireLink: "",
      images: getImageArray("group1"),
      textPlacement: "left",
    },
    {
      id: "group-2",
      title: "Group two.",
      description:
        "A group for the young swimmer wishing to take their swimming skills to the next level. Swimmers are familiar with regional level competitions and are working on building their technical skills and endurance for national level events.",
      minClasses: 3,
      maxClasses: 6,
      billTerm: "monthly",
      enquireLink: "",
      images: getImageArray("group2"),
      textPlacement: "right",
    },
    {
      id: "group-3",
      title: "Group three.",
      description:
        "An advanced group for swimmers who are familiar with national level competitions and wish to further improve their skills for international competitions and careers.",
      minClasses: 3,
      maxClasses: 6,
      billTerm: "monthly",
      enquireLink: "",
      images: getImageArray("group3"),
      textPlacement: "left",
    },
    {
      title: "Dryland training.",
      description:
        "Our dryland training group is fitted for the swimmer who wants to improve their stability, flexibility and strengthening skills outside the water. This group is organised and managed by Players’ own in-house biokineticist.",
      minClasses: 3,
      maxClasses: null,
      billTerm: "monthly",
      enquireLink: "",
      images: getImageArray("dryland"),
      textPlacement: "right",
    },
    {
      id: "ladies",
      title: "Ladies.",
      description:
        "A ladies-only group for swimmers over the age of 18, who want to work on a fitter lifestyle in a man-free environment. The morning sessions offer a perfect way to get your day started.",
      minClasses: 1,
      maxClasses: 3,
      billTerm: "termly",
      enquireLink: "",
      images: getImageArray("ladies"),
      textPlacement: "left",
    },
    {
      id: "masters",
      title: "Masters.",
      description:
        "This group is perfect for anyone over the age of 18, who wants to focus on living a healthier, fitter lifestyle. Use your swimming session as a wake-up session before work, or as a wind-down session after work.",
      minClasses: 1,
      maxClasses: 4,
      billTerm: "termly",
      enquireLink: "",
      images: getImageArray("masters"),
      textPlacement: "right",
    },
  ]

  const heroImages = getImageArray("hero")
  return (
    <Layout>
      <Seo title="Services" />
      <Header images={heroImages} />
      {services.map((service, index) => (
        <ServiceSection
          key={"service-section-" + service.id}
          id={service.id}
          title={service.title}
          description={service.description}
          minClasses={service.minClasses}
          maxClasses={service.maxClasses}
          billTerm={service.billTerm}
          enquireLink={service.enquireLink}
          images={service.images}
          textPlacement={service.textPlacement}
        />
      ))}
      <Slice color="#222222" />
    </Layout>
  )
}

export default ServicesPage
